/** @jsx jsx */
import { Component } from "react";
import { jsx } from "@emotion/core";
import { Provider } from "react-redux";
import { Slide } from "react-reveal";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import configureStore from "./redux/store";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import GetStarted from "./components/GetStarted";
import BluetoothSetup from "./components/BluetoothSetup";
import SettingsWifi from "./components/SettingsWifi";

import { FLOATING_VECTORS } from "./images";
import breakpoints from "./breakpoints";

const store = configureStore({});

const styles = {
  app: {
    textAlign: "center" as "center"
  },
  mainSection: {
    minHeight: "115vh",
    display: "grid",
    gridTemplateRows: "320px",
    background: `url(${FLOATING_VECTORS})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    justifyItems: "center",
    [breakpoints.small]: {
      padding: "15px",
      gridTemplateRows: "0.5fr"
    }
  }
};

class App extends Component {
  render() {
    return (
      <div css={styles.app}>
        <Navbar />
        <main css={styles.mainSection}>
          <Slide bottom duration={1000}>
            <GetStarted />
          </Slide>
          <Provider store={store}>
            <Router>
              <Switch>
                <Route path="/" exact component={Home} />
                <Slide bottom duration={1500}>
                  <Route path="/connect" component={BluetoothSetup} />
                </Slide>
                <Route path="/settings/wifi" component={SettingsWifi} />
              </Switch>
            </Router>
          </Provider>
        </main>
        <Footer />
      </div>
    );
  }
}

export default App;
