/** @jsx jsx */
import { jsx } from "@emotion/core";
import { TRNTBL_COLORS } from "../../styles";

const styles: { [key: string]: any } = {
  fieldset: {
    margin: "15px 0",
    border: "none",
    padding: "0px"
  },
  input: {
    borderRadius: "3px !important",
    border: "none !important",
    backgroundColor: `${TRNTBL_COLORS.inputGrey} !important`,
    boxShadow: "0 10px 20px rgba(0,0,0,0.05), 0 6px 6px rgba(0,0,0,0.05)",
    fontFamily: `Avenir !important`,
    padding: "20px 10px !important",
    outline: "none",
    height: "10px",
    width: "100%",
    fontSize: "1rem",
    margin: "5px 0px"
  },
  label: {
    fontFamily: "Avenir",
    color: TRNTBL_COLORS.black,
    textTransform: "capitalize"
  }
};

type TextInputProps = {
  name: string;
  type: string;
  labelName: string;
  value: string;
  placeholder: string;
  changeFunction:
    | ((event: React.ChangeEvent<HTMLInputElement>) => void)
    | undefined;
};

const TextInput = ({
  type,
  name,
  labelName,
  value,
  placeholder,
  changeFunction
}: TextInputProps) => {
  return (
    <fieldset css={styles.fieldset}>
      <label htmlFor={name} css={styles.label}>
        {labelName}
        <input
          type={type}
          css={styles.input}
          name={name}
          id={name}
          placeholder={placeholder}
          defaultValue={value}
          onChange={changeFunction}
        />
      </label>
    </fieldset>
  );
};

export default TextInput;
