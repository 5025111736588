/** @jsx jsx */
import { jsx, keyframes } from "@emotion/core";
import { useState, useEffect } from "react";
import { TRNTBL_COLORS } from "../../styles";

const fadeIn = keyframes`
  from {
    top: 0; 
    opacity: 0;
  }

  to {
    top: 30px; 
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    top: 30px; 
    opacity: 1;
  }

  to {
    top: 0; 
    opacity: 0;
  }
`;

const styles: { [key: string]: any } = {
  snackbar: {
    visibility: "hidden" as "hidden",
    minWidth: "250px",
    marginLeft: "-125px",
    textAlign: "center",
    borderRadius: "2px",
    padding: "16px",
    position: "fixed" as "fixed",
    "z-index": 1,
    left: "10%",
    top: "30px",
    fontSize: "18px"
  },
  show: {
    visibility: "visible" as "visible",
    animation: `${fadeIn} 0.5s, ${fadeOut} 0.5s 4.7s`
  },
  error: {
    backgroundColor: TRNTBL_COLORS.primaryRed,
    color: TRNTBL_COLORS.white
  },
  success: {
    backgroundColor: TRNTBL_COLORS.primaryGreen,
    color: TRNTBL_COLORS.white
  }
};

type SnackbarProps = {
  message: string;
  type: string;
};

const Snackbar = ({ message, type }: SnackbarProps) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
    }, 5000);
  }, [message]);

  return (
    <div
      css={
        isActive
          ? [styles.snackbar, styles.show, styles[type]]
          : styles.snackbar
      }
    >
      {message}
    </div>
  );
};

export default Snackbar;
