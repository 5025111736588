/** @jsx jsx */
import { jsx } from "@emotion/core";
import breakpoints from "../../breakpoints";

const styles = {
  [breakpoints.small]: {
    fontSize: 18
  },
  fontSize: 24,
  margin: 0
};

const BodyText = (props: any) => <p css={styles} {...props} />;

export default BodyText;
