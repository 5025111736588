/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component } from "react";
import { TextInput } from "./uiElements";
import { Button, BodyText, HeaderTwo } from "./uiElements";
import { TRNTBL_COLORS } from "../styles";

const styles = {
  mainContainer: {
    display: "grid",
    gridTemplateRows: "1fr 1fr",
    rowGap: "50px",
    textAlign: "left",
    height: "50%"
  },
  wifiSelection: {
    width: "100%",
    backgroundColor: TRNTBL_COLORS.inputGrey,
    boxShadow: "0 10px 20px rgba(0,0,0,0.05), 0 6px 6px rgba(0,0,0,0.05)",
    border: "none",
    fontSize: "16px",
    fontFamily: "Avenir",
    marginTop: "10px",
    option: {
      padding: "10px"
    }
  }
};

class SettingsWifi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ssid: "",
      password: ""
    };

    this.handleSSIDChange = this.handleSSIDChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSSIDChange(event) {
    this.setState({ ssid: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleSubmit(event) {
    const { setNetwork } = this.props;
    const { ssid, password } = this.state;
    event.preventDefault();
    setNetwork(ssid, password);
  }

  render() {
    const { availableNetworks } = this.props;

    return (
      <form css={styles.mainContainer} onSubmit={this.handleSubmit}>
        <div css={styles.columnLeft}>
          <HeaderTwo css={{ fontSize: "24px" }}>
            First select your network.
          </HeaderTwo>
          <BodyText css={{ fontSize: "18px" }}>
            {" "}
            Choose a wifi network.
          </BodyText>
          <select
            size="5"
            css={styles.wifiSelection}
            value={this.state.ssid}
            onChange={e => this.handleSSIDChange(e)}
          >
            {availableNetworks.map(ssid => (
              <option key={ssid} value={ssid}>
                {ssid}
              </option>
            ))}
          </select>
        </div>
        <div css={styles.columnRight}>
          <HeaderTwo css={{ fontSize: "24px" }}>
            Then set your credentials.
          </HeaderTwo>
          <BodyText css={{ fontSize: "18px" }}>
            {" "}
            Add your wifi credentials.
          </BodyText>
          <TextInput
            type="text"
            labelName="Wifi Network"
            name="wifi_network"
            id="wifi_network"
            placeholder="Your Wifi Network"
            value={this.state.ssid}
            changeFunction={e => this.handleSSIDChange(e)}
          />
          <TextInput
            type="password"
            labelName="Wifi Password"
            name="wifi_password"
            id="wifi_password"
            placeholder="Your Wifi Password"
            value={this.state.password}
            changeFunction={e => this.handlePasswordChange(e)}
          />
          <br />
          <Button text="Connect WiFi" shape="rounded" color="green" />
        </div>
      </form>
    );
  }
}
export default SettingsWifi;
