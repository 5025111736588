/** @jsx jsx */
import { jsx } from "@emotion/core";

const HeaderTwo = (props: any) => (
  <h2
    css={{
      fontSize: 30,
      fontWeight: 500,
      margin: 0
    }}
    {...props}
  />
);

export default HeaderTwo;
