/** @jsx jsx */
import { jsx } from "@emotion/core";
import { BodyText, HeaderOne } from "./uiElements";
import { TRNTBL_COLORS } from "../styles";

const styles = {
  mainContainer: {
    display: "grid",
    marginTop: "40px"
  },
  textContainer: {
    maxWidth: "800px"
  }
};

const GetStarted = () => {
  return (
    <div css={styles.mainContainer}>
      <div css={styles.textContainer}>
        <HeaderOne>Let’s get you set up.</HeaderOne>
        <BodyText>
          Before we start, make sure you have your Sonos speaker, a Wi-Fi
          connection,{" "}
          <a
            css={{
              color: TRNTBL_COLORS.primaryGreen,
              fontWeight: 500
            }}
            href="http://bit.ly/trntbl-chrome"
            target="_blank"
            rel="noopener noreferrer"
          >
            our Chrome extension,
          </a>{" "}
          and a great record to play.
        </BodyText>
        <BodyText css={{ marginTop: "10px" }}>
          Now that you’re ready, please follow the steps below.
        </BodyText>
      </div>
    </div>
  );
};

export default GetStarted;
