import { combineReducers } from "redux";

const initialState = {
  ipAddress: null,
  serialNum: null
};

const device = (state = initialState, action) => {
  switch (action.type) {
    case "SET_IPADDRESS":
      console.log(action);
      return { serialNum: state.serialNum, ipAddress: action.ipAddress };
    default:
      return state;
  }
};

export default combineReducers({
  device
});
