const TRNTBL_COLORS = {
  white: "#ffffff",
  black: "#000000",
  primaryPink: "#FFE3F6",
  primaryGreen: "#325C4D",
  primaryYellow: "#FFF6A9",
  primaryGrey: "#D7DDE5",
  primaryRed: "#DA565E",
  inputGrey: "#FAFAFA"
};

export default TRNTBL_COLORS;
