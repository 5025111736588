/** @jsx jsx */
import { jsx } from "@emotion/core";

const HeaderOne = (props: any) => (
  <h1
    css={{
      fontSize: 35,
      fontWeight: 500
    }}
    {...props}
  />
);

export default HeaderOne;
