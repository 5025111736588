/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component } from "react";
import { connect } from "react-redux";
import SettingsWifi from "./SettingsWifi";
import { Button, BodyText, HeaderTwo, Snackbar } from "./uiElements";
import { PROFILE_ONE } from "../images";
import { TRNTBL_COLORS } from "../styles";
import breakpoints from "../breakpoints";
import { isIOS } from "react-device-detect";

const TRNTBL_SERVICE_ID = "967c8518-a801-4d25-b215-34c30176346a";
const CONNECT_CHARACHTERISTIC_ID = "be9c6f57-04ba-4ebb-bdb9-1cd6ebe78813";
const NETWORK_CHARACHTERISTIC_ID = "2751db6d-c940-4f2a-8afa-07b56c7c414e";

const styles = {
  mainContainer: {
    display: "grid",
    [breakpoints.small]: {
      margin: "10px 0",
      gridTemplateRows: "1fr auto",
      gridRowGap: "30px",
      placeItems: "center"
    },
    [breakpoints.mediumUp]: {
      gridTemplateColumns: "370px 1fr",
      gridColumnGap: "20px",
      gridTemplateRows: "1fr"
    }
  },
  notSupportedContainer: {
    display: "grid",
    [breakpoints.small]: {
      gridTemplateRows: "300px",
      placeSelf: "start"
    }
  },
  notSupportedBody: {
    maxWidth: "500px"
  },
  notSupportedHeader: {
    [breakpoints.small]: {
      fontSize: "20px",
      textAlign: "center",
      marginBottom: "10px"
    },
    [breakpoints.mediumUp]: {
      fontSize: "24px",
      textAlign: "center",
      marginBottom: "10px"
    }
  },
  imageContainer: {
    backgroundColor: TRNTBL_COLORS.white,
    "box-shadow": "0 10px 20px rgba(0,0,0,0.10),0 6px 6px rgba(0,0,0,0.15)",
    [breakpoints.small]: {
      width: "150px"
    },
    [breakpoints.mediumUp]: {
      width: "300px"
    }
  },
  image: {
    width: "100%"
  },
  textContainer: {
    maxWidth: "500px"
  }
};

function dvToString(value) {
  let output = "";
  for (let byte = 0; byte < value.byteLength; byte++) {
    output += String.fromCharCode(value.getUint8(byte));
  }
  return output;
}

function stringToDV(str) {
  var buf = new ArrayBuffer(str.length);
  var bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

class BluetoothSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableNetworks: [],
      loading: false,
      snackbar: {
        message: "",
        type: ""
      }
    };

    this.btService = null;

    this.setNetwork = this.setNetwork.bind(this);
  }

  scanBluetooth() {
    const { dispatch, history } = this.props;
    if (!navigator.bluetooth) {
      this.setState(prevState => ({
        snackbar: {
          ...prevState.snackbar,
          message: "Bluetooth is not supported",
          type: "error"
        }
      }));
      return;
    }
    this.setState({ loading: "Looking for TRNTBL" });
    navigator.bluetooth
      .requestDevice({
        filters: [
          {
            services: [TRNTBL_SERVICE_ID]
          }
        ]
      })
      .then(device => {
        console.log(device);
        return device.gatt.connect();
      })
      .then(server => {
        console.log(server);
        this.setState({ loading: "Connected! Checking IP" });
        return server.getPrimaryService(TRNTBL_SERVICE_ID);
      })
      .then(service => {
        console.log(service);
        this.btService = service;
        return service.getCharacteristic(CONNECT_CHARACHTERISTIC_ID);
      })
      .then(characteristic => {
        return characteristic.readValue();
      })
      .then(value => {
        const ipAddress = dvToString(value);
        if (ipAddress) {
          console.log(ipAddress);
          dispatch({ type: "SET_IPADDRESS", ipAddress });
          history.push("/");
        } else {
          console.log("No IP, must scan...");
          this.setState({ loading: "Finding networks..." });
          return this.getAvailableNetworks();
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        console.error(err);
      });
  }

  getAvailableNetworks() {
    if (!this.btService) {
      console.error("No bluetooth service");
      this.setState(prevState => ({
        snackbar: {
          ...prevState.snackbar,
          message: "No bluetooth service available",
          type: "error"
        }
      }));
      return;
    }

    this.btService
      .getCharacteristic(NETWORK_CHARACHTERISTIC_ID)
      .then(characteristic => {
        return characteristic.readValue();
      })
      .then(value => {
        const networks = dvToString(value).split(", ");
        const availableNetworks = [...new Set(networks)].filter(n => n !== "");
        this.setState({
          availableNetworks,
          loading: false
        });
      })
      .catch(err => {
        this.setState(prevState => ({
          snackbar: {
            ...prevState.snackbar,
            message: "Whoops! Something went wrong. Give it another try",
            type: "error"
          }
        }));
        console.error(err);
      });
  }

  setNetwork(ssid, password) {
    if (!this.btService) {
      console.error("No bluetooth service");
      this.setState(prevState => ({
        ...prevState.snackbar,
        snackbar: { message: "No bluetooth service available", type: "error" }
      }));
      return;
    }
    const { dispatch, history } = this.props;
    let connectCharacteristic;
    this.setState({ loading: "Saving Wifi Info..." });
    this.btService
      .getCharacteristic(CONNECT_CHARACHTERISTIC_ID)
      .then(characteristic => {
        console.log(characteristic);
        connectCharacteristic = characteristic;
        return characteristic.writeValue(stringToDV(`${ssid}..${password}`));
      })
      .then(_ => {
        this.setState(prevState => ({
          loading: "Connected!",
          availableNetworks: [],
          snackbar: {
            ...prevState.snackbar,
            message: "Wooo! We are connected",
            type: "success"
          }
        }));
        return connectCharacteristic.readValue();
      })
      .then(value => {
        const ipAddress = dvToString(value);
        dispatch({ type: "SET_IPADDRESS", ipAddress });
        history.push("/");
      })
      .catch(err => {
        this.setState({ loading: false });
        console.error(`WTF: ${err}`);

        this.setState(prevState => ({
          snackbar: {
            ...prevState.snackbar,
            message: "Whoops! Something went wrong. Give it another try",
            type: "error"
          },
          loading: false
        }));
      });
  }

  render() {
    const { loading, availableNetworks, snackbar } = this.state;
    if (loading) {
      return <BodyText>{loading}</BodyText>;
    } else if (availableNetworks.length > 0) {
      return (
        <SettingsWifi
          availableNetworks={availableNetworks}
          setNetwork={this.setNetwork}
        />
      );
    } else {
      return !!navigator.bluetooth ? (
        <div css={styles.mainContainer}>
          <div css={styles.imageContainer}>
            <img src={PROFILE_ONE} css={styles.image} alt="bluetooth setup" />
          </div>
          <div css={styles.textContainer}>
            <HeaderTwo css={{ textAlign: "left" }}>Scan Time</HeaderTwo>
            <BodyText
              css={{
                textAlign: "left",
                fontSize: "18px",
                marginBottom: "20px"
              }}
            >
              Wait 2 min for your TRNTBL to boot once it’s plugged into the
              wall. Then press this button so your computer can pair with the
              player.
            </BodyText>
            <Button
              clickFunction={() => this.scanBluetooth()}
              text="Scan bluetooth"
              shape="rounded"
              color="green"
            />
          </div>
          {snackbar ? (
            <Snackbar message={snackbar.message} type={snackbar.type} />
          ) : null}
        </div>
      ) : isIOS ? (
        <div css={styles.notSupportedContainer}>
          <div css={styles.notSupportedBody}>
            <HeaderTwo css={styles.notSupportedHeader}>
              Bummer! We don't support your browser.
            </HeaderTwo>
            <BodyText
              css={{
                textAlign: "center",
                marginBottom: "20px"
              }}
            >
              In order for us to connect to your player, you need to be using
              Google's Chrome browser on your desktop computer or Android
              device.
            </BodyText>
          </div>
          {snackbar ? (
            <Snackbar message={snackbar.message} type={snackbar.type} />
          ) : null}
        </div>
      ) : (
        <div css={styles.notSupportedContainer}>
          <div css={styles.notSupportedBody}>
            <HeaderTwo css={styles.notSupportedHeader}>
              Bummer! We don't support your browser.
            </HeaderTwo>
            <BodyText
              css={{
                textAlign: "center",
                marginBottom: "20px"
              }}
            >
              In order for us to connect to your player, you need to be using
              Google's Chrome browser.
            </BodyText>
            <BodyText
              css={{
                textAlign: "center",
                marginBottom: "20px"
              }}
            >
              <a
                css={{
                  color: TRNTBL_COLORS.primaryGreen,
                  fontWeight: 500
                }}
                href="https://www.google.com/chrome/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download Chrome
              </a>
            </BodyText>
            <BodyText
              css={{
                textAlign: "center",
                marginBottom: "20px"
              }}
            >
              When you are finished installing Chrome come back here and we will
              get you all set up.
            </BodyText>
          </div>
          {snackbar ? (
            <Snackbar message={snackbar.message} type={snackbar.type} />
          ) : null}
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  ipAddress: state.ipAddress
});

export default connect(mapStateToProps)(BluetoothSetup);
