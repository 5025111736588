/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component } from "react";
import { connect } from "react-redux";
import { BodyText } from "./uiElements";
import { TRNTBL_COLORS } from "../styles";
import breakpoints from "../breakpoints";

const styles = {
  buttonLink: {
    cursor: "pointer",
    "font-weight": "400",
    "font-family": "Avenir",
    display: "block",
    fontSize: "18px",
    padding: "10px 30px",
    border: "none",
    "border-radius": "20px",
    transition: "0.1s",
    "box-shadow": "0 10px 20px rgba(0,0,0,0.10), 0 6px 6px rgba(0,0,0,0.15)",
    backgroundColor: TRNTBL_COLORS.primaryGreen,
    color: TRNTBL_COLORS.white,
    textDecoration: "none",
    marginTop: "10px"
  },
  body: {
    [breakpoints.small]: {
      marginTop: "15px"
    }
  }
};

class Home extends Component {
  componentDidMount() {
    const { ipAddress, history } = this.props;
    if (!ipAddress) {
      console.log("no ipaddress");
      history.push("/connect");
    }
  }

  render() {
    const { ipAddress } = this.props;
    return (
      <BodyText css={styles.body}>
        Connected to: {ipAddress}
        <br />
        <br />
        Lets get this party started!{" "}
        <a
          css={styles.buttonLink}
          href="http://bit.ly/trntbl-chrome"
          target="_blank"
          rel="noopener noreferrer"
        >
          Get The TRNTBL Chrome Extension
        </a>{" "}
      </BodyText>
    );
  }
}

const mapStateToProps = state => {
  console.log("home state:", state);
  return {
    ipAddress: state.device.ipAddress
  };
};

export default connect(mapStateToProps)(Home);
