/** @jsx jsx */
import { jsx } from "@emotion/core";
import { BLACK_LOGO } from "../images";

const styles = {
  navbar: {
    display: "grid",
    height: "120px"
  },
  logoContainer: {
    justifySelf: "center",
    alignSelf: "center"
  },
  logo: {
    width: "200px",
    height: "100%"
  }
};

const Navbar = () => {
  return (
    <div css={styles.navbar}>
      <div css={styles.logoContainer}>
        <img src={BLACK_LOGO} css={styles.logo} alt="trntbl" />
      </div>
    </div>
  );
};

export default Navbar;
