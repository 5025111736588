/** @jsx jsx */
import { jsx } from "@emotion/core";
import { INSTAGRAM_LOGO, WHITE_LOGO, VNYL_LOGO_SMALL } from "../images";
import { TRNTBL_COLORS } from "../styles";
import { BodyText } from "./uiElements";
import breakpoints from "../breakpoints";

const styles = {
  footer: {
    display: "grid",
    height: "400px",
    backgroundColor: TRNTBL_COLORS.black,
    color: TRNTBL_COLORS.white,
    padding: "50px"
  },
  twoColumnContainer: {
    display: "grid",
    [breakpoints.small]: {
      gridTemplateRows: "1fr 1fr"
    },
    [breakpoints.mediumUp]: {
      gridTemplateColumns: "1fr 1fr"
    }
  },
  logo: {
    width: "120px",
    height: "100%"
  },
  icon: {
    margin: "0 20px",
    height: "100%",
    vnyl: {
      width: "60px"
    },
    instagram: {
      width: "40px"
    }
  },
  footerLink: {
    fontSize: "18px",
    color: TRNTBL_COLORS.white,
    textDecoration: "none",
    padding: "10px 20px 20px"
  },
  copyright: {
    alignSelf: "end",
    justifySelf: "center"
  }
};

const Footer = () => {
  const d = new Date();
  return (
    <footer css={styles.footer}>
      <div css={styles.twoColumnContainer}>
        <div css={{ placeSelf: "center" }}>
          <div css={{ textAlign: "left" }}>
            <img src={WHITE_LOGO} css={styles.logo} alt="trntbl" />
            <BodyText css={{ marginTop: "10px" }}>Let’s get physical.</BodyText>
          </div>
        </div>
        <div css={{ placeSelf: "center" }}>
          <div>
            <div css={{ marginBottom: "20px" }}>
              <a
                css={styles.footerLink}
                href="https://vnyl.wufoo.com/forms/ko3u3e81vx71oq/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact
              </a>
              <a
                css={styles.footerLink}
                href="https://trntbl.co"
                target="_blank"
                rel="noopener noreferrer"
              >
                Media
              </a>
              <a
                css={styles.footerLink}
                href="https://trntbl.co/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms
              </a>
            </div>
            <div css={{ textAlign: "right" }}>
              <img
                src={VNYL_LOGO_SMALL}
                css={[styles.icon, styles.icon.vnyl]}
                alt="trntbl"
              />
              <img
                src={INSTAGRAM_LOGO}
                css={[styles.icon, styles.icon.instagram]}
                alt="trntbl"
              />
            </div>
          </div>
        </div>
      </div>
      <div css={styles.copyright}>
        ©{d.getFullYear()}, TRNTBL® is a registered trademark.
      </div>
    </footer>
  );
};

export default Footer;
