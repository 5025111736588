/** @jsx jsx */
import { jsx } from "@emotion/core";
import { TRNTBL_COLORS } from "../../styles";
import breakpoints from "../../breakpoints";

interface BaseButtonProps {
  text: string;
  color: string;
  shape: string;
  clickFunction:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
}

const styles: { [key: string]: any } = {
  button: {
    cursor: "pointer",
    "font-weight": "400",
    "font-family": "Avenir",
    display: "block",
    fontSize: "18px",
    padding: "10px 30px",
    border: "none",
    transition: "0.1s",
    "box-shadow": "0 10px 20px rgba(0,0,0,0.10), 0 6px 6px rgba(0,0,0,0.15)",
    [breakpoints.small]: {
      margin: "0 auto"
    }
  },
  rounded: {
    "border-radius": "20px"
  },
  flat: {
    "border-radius": "0px"
  },
  pink: {
    backgroundColor: TRNTBL_COLORS.primaryPink,
    color: TRNTBL_COLORS.white
  },
  yellow: {
    backgroundColor: TRNTBL_COLORS.primaryYellow,
    color: TRNTBL_COLORS.white
  },
  green: {
    backgroundColor: TRNTBL_COLORS.primaryGreen,
    color: TRNTBL_COLORS.white
  }
};

const Button = ({ text, color, shape, clickFunction }: BaseButtonProps) => {
  return (
    <button
      onClick={clickFunction}
      css={[styles.button, styles[color], styles[shape]]}
    >
      {text}
    </button>
  );
};

export default Button;
